import React from "react";

function SingleService({ data }) {
  return (
    <div className=" box-content h-30 bg-white drop-shadow-xl shadow-[#00000029] p-10 transition ease-in-out delay-100  hover:-translate-y-1 hover:scale-120 hover: duration-700 hover:shadow-[#6E49B9] hover:shadow-lg rounded-sm ">
      <div className="flex-row items-center flex my-3">
        <img
          src={data?.image}
          alt="Admission Assistance"
          className="h-20 w-20 mr-3"
        />
        <h1 className=" text-2xl mb-3 font-[poppins] font-semibold">
          {data?.title}
        </h1>
      </div>

      <p className="font-[poppins] text-sm font-light">{data?.content}</p>
    </div>
  );
}

export default SingleService;
