import React, { useState } from 'react'
import BoatIcon from '../../../assets/image/child.jpeg'
import AiChatBoat from '../../organization/boat_page'
import { Transition } from "@headlessui/react";

function BoatService() {
  const [showBoat, setShowBoat] = useState(false)
  const openShowBoat = () => {
    setShowBoat(!showBoat)
  }
  return (
    <div className='fixed bottom-0 right-0 mb-[4.5rem] mr-4'>
      <div className='relative inline-block'>
        <button className={`h-10 w-44 bg-white shadow-md shadow-[#7784d2d0] flex items-center  rounded-full px-4', ${!showBoat ? "animate-bounce" : 'animate-none'}`}
          onClick={() => openShowBoat()}

        >
          <img
            src={BoatIcon}
            alt="boat"
            className="h-auto w-8 m-3 rounded-full"
            
          />
          <h3 className='text-sm font-bold font-[poppins] text-[#2C387E]'> Live Chat</h3>
        </button>

      </div>

      <Transition
        show={showBoat}
        enter="transform transition duration-500"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transform duration-500 transition ease-in-out"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
      >
        <AiChatBoat />
      </Transition>


    </div>
  )
}

export default BoatService