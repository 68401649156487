import React from "react";

const VideoCompontent = ({ value, index, gotToNext, videoRef, mute }) => {
  return (
    <video
      preload="auto"
      poster={value.thumbnail}
      muted={mute}
      ref={videoRef}
      autoPlay
      key={index}
      className={" h-full w-full object-cover"}
      controlsList="nodownload"
      onEnded={() => gotToNext(index)}
    >
      <source src={value.url} type="video/mp4" />
    </video>
  );
};

export default VideoCompontent;
