import React from "react";
import Devider from "../../atom/devider";

function SucessTitle({ title, subtitle, isCenter }) {
  return (
    <div>
      <div
        className={`w-screen  flex flex-col   ${
          isCenter ? "items-center p-2" : "items-center md:items-start md:p-8"
        }`}
      >
        {title ? (
          <h6 className="text-[#2C387E] font-[poppins] font-normal text-lg mb-5">
            Feed Back
          </h6>
        ) : null}
        <span className="text-[#1E1926] font-[poppins] font-normal text-2xl md:text-3xl w-[23rem] text-center leading-[3rem]">
          {subtitle}
        </span>
        <div
          className={
            isCenter === false && "justify-end md:w-[22.5rem] flex md:pt-1"
          }
        >
          <Devider />
        </div>
      </div>
    </div>
  );
}

export default SucessTitle;
