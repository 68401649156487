import React from "react";
import SingleAdmission from "../../molecules/single_admission";

function AdmissionScroll({ data }) {
  return (
    <div className="w-screen h-3/6">
      <div className="overflow-x-scroll flex scrollbar-hide  w-full   px-10 gap-8 pb-10 pt-3 ">
        {data.map((value, index) => {
          return <SingleAdmission key={index} title={value?.title} thumbnil={value?.thumbnil} />;
        })}
      </div>
    </div>
  );
}

export default AdmissionScroll;
