import AdmmssionAssistance from "../../../assets/svg/customer-care.svg";
import Career from "../../../assets/svg/Career.svg";
import Educational from "../../../assets/svg/Educational.svg";
import Reserved from "../../../assets/svg/reserved.svg";
import Loan from "../../../assets/svg/Loan.svg";
import Campus from "../../../assets/svg/Campus.svg";
const Data = [
  {
    title: "Admission Assistance",
    content: `We assist you to choose the right course and university and guide you in preparing a high-quality application, including necessary document preparation and verification. We aim to simplify the admission process and increase the chances of successful enrollment.`,
    image: AdmmssionAssistance,
  },
  {
    title: "Career Guidance",
    content: `We provide you with advice and support to guide you identify and pursuing career paths that align with your interests, skills and maximize your potential to achieve your career goals.`,
    image: Career,
  },
  {
    title: "Educational Counselling",
    content: `We provide you with assistance and guidance in making the right choices in your studies,
    Be it your educational plans career aspirations, choice of stream and specialization as well as the selection of college or university as per your interest and preferences.`,
    image: Educational,
  },
  {
    title: "Students Education Loan Assistance",
    content: `We offer support and guidance throughout the entire loan process. This includes helping you with the application process.`,
    image: Loan,
  },
  {
    title: "Campus Guidance",
    content: `We Provide guidance on factors such as campus location, campus facilities, academic programs, extracurricular activities, and campus culture. We also provide information on admission requirements, application deadlines, and scholarship opportunities. Our aim is to help you make informed decisions about your campus choices and ensure a positive college experience.`,
    image: Campus,
  },
  {
    title: "Seat Reservation Facilities",
    content: `We provide seat reservation services for students seeking admission to educational institutions. Our support includes helping with the application process, securing seats in preferred courses and universities, and ensuring timely enrollment. Our goal is to simplify the admission process and ensure successful enrollment.`,
    image: Reserved,
  },
];

export default Data;
