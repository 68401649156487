import React from "react";
import Logo from "../../../assets/image/logo2.png";
import SearchBox from "../../molecules/search";
import TopCourses from "../../molecules/topCourses";
import Hamburger from "hamburger-react";
function Header({ setIsOpen, isOpen }) {
  return (
    <div className="flex-row bg-[#2C387E] p-3 flex w-full justify-between  items-center">
      <div className=" h-20 w-36 md:h-24  md:w-52  bg-white rounded-sm">
        <img
          src={Logo}
          alt="logo"
          className="h-full w-full"
        />
      </div>
      {/* mobile drawer */}
      <div className="md:hidden">
        <Hamburger
          onToggle={() => {
            setIsOpen(true)
          }}
          color="#fff"
          size={25}
          toggled={isOpen}
        />
      </div>
      {/* web */}
      <div className="hidden md:flex flex-row items-center">
        <TopCourses />
        <SearchBox />
      </div>
    </div>
  );
}

export default Header;
