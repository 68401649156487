import React from "react";
import AdmissionScroll from "../../organization/admission_scroll";

function Admission({ title, data }) {
  console.log(data)
  return (
    <div>
      <div className="w-full flex justify-center mb-1">
        <h1 className="font-[poppins] text-black font-normal md:text-4xl text-2xl">
          {title}
        </h1>
      </div>
      <AdmissionScroll data={data} />
    </div>
  );
}

export default Admission;
