import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../../stging";

const useGetLocations = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getLocation();
  }, []);
    
    //location hook
    

  const getLocation = () => {
    // let locationString = localStorage.getItem("locations");
    // let locations = JSON.parse(locationString);
    // if (locations) {
    //   setData(locations);
    // } else {
      try {
        axios
          .get(`${baseUrl}locations`)
          .then((res) => {
            // localStorage.setItem("locations", JSON.stringify(res?.data?.data));
            setData(res?.data?.data);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log(error);
      }
    // }
  };

  return { data };
};

export default useGetLocations;
