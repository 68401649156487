import React from 'react';
import Lottie from "lottie-react";
import book from '../../../assets/lottie/book.json';
import Input from '../../molecules/input';

const FormCompont = ({ loading, cancel }) => {
  const link = "https://docs.google.com/forms/d/e/1FAIpQLSe3bqA61L86yPiGVtQJeWGcgdbE9QeUOj1plfvuiMgAoYQxvQ/formResponse";

  return (
    <div className="h-full w-full flex justify-center items-center">
      {!loading ? (
        <div className="p-4 h-full">
          <form action={link} className="grid md:grid-cols-2 md:gap-2">
            <Input placeholder="Enter Name" name="entry.376417483" />
            <Input placeholder="Enter Email" type="email" name="entry.1900387278" />
            <div className="flex flex-col">
              <label className="mb-2 text-gray-700" htmlFor="gender">Gender:</label>
              <div className="flex flex-row justify-evenly">
                <div className="flex items-center">
                  <input type="radio" id="male" name="entry.1700623654" value="Male" />
                  <label className="ml-2" htmlFor="male">Male</label>
                </div>
                <div className="flex items-center">
                  <input type="radio" id="female" name="entry.1700623654" value="Female" />
                  <label className="ml-2" htmlFor="female">Female</label>
                </div>
              </div>
            </div>
            <Input placeholder="Enter Parent contact number" name="entry.1693498513" />
            <Input placeholder="Enter Mobile Number" name="entry.1382493829" />
            <Input placeholder="Residential Address" name="entry.10405612" />
            <Input placeholder="Enter Quaification" name="entry.890047200" />
            <Input placeholder="Mark obtained in 12th And degree" name="entry.837072236" />
            <Input placeholder="Preferred collage / Course" name="entry.673019445" />
            <div className="flex flex-row justify-between m-2">
              <button
                type="reset"
                onClick={cancel}
                className="h-12 w-36 bg-white border-2 bg-transparent shadow-md rounded-md flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 mx-2"
              >
                <h6 className="text-black text-sm font-poppins">Cancel</h6>
              </button>
              <button
                type="submit"
                className="h-12 w-36 bg-gradient-to-r from-[#0BD726AD] to-[#49B81A] shadow-lg rounded-md flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
              >
                <h6 className="text-white text-sm font-poppins">Submit</h6>
              </button>
            </div>
          </form>
        </div>
      ) : (
        <Lottie animationData={book} loop={true} className="h-52 w-full" />
      )}
    </div>
  );
};

export default FormCompont;
