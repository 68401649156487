import React from "react";
import { useNavigate } from "react-router-dom";

function ApplayNowButtons() {
  const navigate = useNavigate();

  const gotoScreen = () => {
    navigate("Applay");
  };
  return (
    <div className="flex flex-row">
      <button
        className="h-12 w-36 bg-gradient-to-r from-[#0BD726AD] to-[#49B81A]  shadow-lg rounded-md flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
        onClick={() => gotoScreen()}
      >
        <h6 className="text-white text-sm font-[poppins]">Apply Online</h6>
      </button>
      <button
        className="h-12 w-36 ml-4 bg-[#fff] shadow-lg rounded-md flex items-center justify-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
        onClick={() =>
          window.open("https://www.instagram.com/studentsonlychoice/")
        }
      >
        <h6 className="text-black text-sm font-[poppins]">Discover</h6>
      </button>
    </div>
  );
}

export default ApplayNowButtons;
