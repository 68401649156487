import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ApplayForm from "../../screens/applayform";
import HomeScreen from "../../screens/homeScreen";
function Navigation() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
      path: "/applay",
      element: <ApplayForm />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default Navigation;
