import React from "react";
import SucessTitle from "../../molecules/success_title";
import Lottie from "lottie-react";
import WhatsApp from "../../../assets/lottie/whatsapp.json";
import BoyImage from "../../../assets/image/boy.jpg";
function AboutUs() {
  return (
    <div className="w-screen">
      <SucessTitle
        subtitle="Moving beyond product innovation to gain a competitive advantage"
        isCenter={false}
      />
      <div className="flex justify-between md:items-center">
        <div className="flex-col">
          <div className="p-8">
            <p className="text-[#707070] font-[poppins] text-sm md:w-[75%] leading-7 text-left">
              Students Only Choice is the pioneer in domestic and overseas
              education consultancy since 2018. With quality credentials and our
              trustworthy service guidance we support students to reach the best
              possible academic heights based on their academic merits and
              financial capacities. Facilitating the best educational
              opportunities in both national and prestigious universities
              abroad, we rightly set the academic goals for aspiring students.
              Complementing the aptitudes of students, we recommended nothing
              less than the best in their academic pursuit. Relieving you of
              uncertainties and confusions regarding the career options, we can
              serve students by providing the best counseling and guidance to
              help you make the right decision.
            </p>
          </div>
        </div>
        {/* image  */}
        <div className="hidden md:flex">
          <div className="h-96 w-80  rounded-l-full ">
            <img
              src={BoyImage}
              alt="boy"
              className="h-96 w-96 object-cover rounded-l-full "
            />
          </div>
        </div>
      </div>
      <div className="p-8">
        <button
          className="h-12 w-60 bg-[#fff] shadow-lg rounded-lg flex justify-between items-center flex-row px-3 "
          onClick={() => window.open("https://wa.link/jhr7x9")}
        >
          <h6 className="text-black text-sm font-[poppins] text-center">
            Get Free Consultation
          </h6>
          <Lottie animationData={WhatsApp} loop={true} className="h-10 w-10" />
        </button>
      </div>
    </div>
  );
}

export default AboutUs;
