import React from "react";

function SingleAdmission({title,thumbnil}) {
  return (
    <div>
      <div className="h-44 w-60 bg-white drop-shadow-lg rounded-xl overflow-hidden">
        {/* header */}
        <div className="p-2">
          <div
            className="from-[#0C20D5] to-[#BD006BCC] bg-gradient-to-b h-10 w-full rounded-lg
          flex justify-center items-center
          "
          >
            <h4 className="text-[Roboto] text-white text-xl font-black">
              {title}
            </h4>
          </div>
        </div>
        {/* image */}
        <img
          className="h-full w-full object-cover"
          src={thumbnil}
          alt="admission"
        />
      </div>
    </div>
  );
}

export default SingleAdmission;
