import React from "react";
import Navigation from "./routes/navigation";

const App = () => {
  return (
    <div className="flex w-screen h-screen overflow-x-scroll scrollbar-hide bg-[#F2F2F2]">
      <Navigation />
    </div>
  );
};

export default App;
