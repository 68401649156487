import React from 'react'
import { FiInstagram } from "react-icons/fi";
import { FaWhatsapp } from 'react-icons/fa';
import { FiMail } from "react-icons/fi";
import { FiMap } from "react-icons/fi";

import { Link } from 'react-router-dom';
function Footer() {
    const instagramUrl = 'https://www.instagram.com/direct/t/17850885719679813/';
    const whatsappUrl = "https://wa.link/jhr7x9"
    const mailLink ="mailto:studentsochoice@gmail.com"
    const maplink = "https://goo.gl/maps/593hZaVZpvFbTRLf6"
    const termsLink ="https://www.termsfeed.com/live/facee246-bb64-4956-b857-e4cf275e2e69"
  return (
    <footer>
          <div className="h-60  bg-[#2C387E] rounded-t-xl  ">
              <div className=' h-20 flex items-center justify-center ' >
                     <Link to={instagramUrl} target="_blank">
                      <FiInstagram className='text-white text-2xl  m-3'  />
                  </Link>
                  <Link to={whatsappUrl} target="_blank">
                      <FaWhatsapp className='text-white text-2xl m-3'  />
                  </Link>
                  <Link to={mailLink} target="_blank">
                      <FiMail className='text-white text-2xl  m-3'  />
                  </Link>
                  <Link to={maplink} target="_blank">
                      <FiMap className='text-white text-2xl  m-3'  />
                  </Link>
              </div>
              <div className='justify-center flex'>
              <p  className='text-white font-[poppins] text-xs '>Copyright © 2023 students only choice - All Rights Reserved.</p>
              </div>
              <div className='p-8'> 
                  <Link to={termsLink} >
                      <h1 className='text-white underline'>Terms & conditions</h1>
                  </Link>
              </div>
          
        </div>
      </footer>
  )
}

export default Footer