import React, { useEffect, useState } from "react";
import FormCompont from "../applayform";

const FormPopUp = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      openPopup()
    }, 5000)
  }, [])

  return (
    <div className={`fixed inset-0 flex items-center justify-center ${isOpen ? "z-50" : "-z-10"}`}>
      <button
        className="fixed inset-0 bg-black opacity-75"
        onClick={closePopup}
      ></button>
      {isOpen && (
        <div className="bg-white p-8 md:w-1/2   relative z-50">
          <h2 className="text-2xl mb-4 text-center font-[robito]">Unlock Your Potential & Transform Your Education Journey with Expert Guidance!</h2>
          <FormCompont cancel={() => closePopup()} />
        </div>
      )}

    </div>
  );
};

export default FormPopUp;
