import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../../stging";

const useGetTopCourses = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getTopCourses();
  }, []);

  const getTopCourses = () => {
    // let topCourseString = localStorage.getItem("topCourses");
    // let topCourses = JSON.parse(topCourseString);
    // if (topCourses) {
    //   setData(topCourses);
    // } else {
      try {
        axios
          .get(`${baseUrl}topCourses`)
          .then((res) => {
            // localStorage.setItem("topCourses", JSON.stringify(res?.data?.data));
            setData(res?.data?.data);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log(error);
      }
    // }
  };

  return { data };
};

export default useGetTopCourses;
