import React from "react";

function GratingHeader() {
  return (
    <>
      <h3 className=" my-4 text-sm text-[#2C387E] font-[poppins] ">
        SPECIAL OFFER FIRST CUSTOMER
      </h3>
      <div className="text-black font-black sm:text-5xl max-sm:text-4xl font-[Sora] sm:leading-[1.2] max-sm:leading-[1.2] animate-drop">
        Your <span className="text-[#F32424]">success</span> journey start with
        us!
      </div>
      <p className="text-xs my-4 text-[#6B6B6B] font-[poppins]">
        Students Only Choice Can Fulfil Your Education Dream As Per Your Best
        Fit with world top universities and colleges.
      </p>
    </>
  );
}

export default GratingHeader;
