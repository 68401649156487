import React, { useEffect, useState } from "react";
import SearchBox from "../../components/molecules/search";
import TopCourses from "../../components/molecules/topCourses";
import Header from "../../components/organization/Header";
import Drawer from "../../components/template/drawer";

import Footer from "../../components/template/footer";
import FormCompont from "../../components/template/applayform";
import { useNavigate } from "react-router-dom";
function ApplayForm() {
  const [isOpen, setIsOpen] = useState(false);
  const [loding, setLoading] = useState(true)
  const navigate = useNavigate()

  const openFunction = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    lodingFunction()
  }, [])

  const lodingFunction = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }

  const goBack = () => {
    navigate("/")
  }

  return (
    <div className="w-screen">
      {/* header */}
      <header>
        <Header setIsOpen={() => openFunction()} />
      </header>

      {/* mobile drawer show */}
      <div className="md:hidden">
        <Drawer isOpen={isOpen} setIsOpen={() => openFunction()}>
          <div className="md:hidden">
            <div className="my-4 w-3/4">
              <TopCourses />
            </div>
            <SearchBox />
          </div>
        </Drawer>
      </div>

      {/* ------------------------------------------------ */}
      {/* form */}
      <FormCompont loding={loding} cancel={() => goBack()} />

      {/* footer */}
      <Footer />
    </div>
  );
}

export default ApplayForm;
