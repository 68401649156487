import React from "react";

function Input({ placeholder, type, onChange, value, required, onBlur,name }) {
  return (
    <input
      className=" w-full h-10 bg-white border-[#707070] border-solid border-[1px] outline-[#0D8CF8] shadow-[#00000029] rounded-md drop-shadow-lg px-3 my-2 font-[poppins] font-normal"
      type={type}
       name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
      onBlur={onBlur}
    
    />
  );
}

export default Input;
