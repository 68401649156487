import React from "react";
import useGetTopCollages from "../../../hooks/getTopCollages/useGetTopCourses";

function TopCourses() {
  const { data } = useGetTopCollages();

  return (
    <div className="mx-3 rounded-md h-10  flex flex-row bg-white w-72 items-center px-4">
      <select
        id="collages"
        className="outline-none  bg-transparent w-60 font-semibold font-[roboto]"
      >
        <option value="" disabled selected hidden>
          TOP COURSES
        </option>

        {data.length !== 0 &&
          data?.map((value, index) => {
            return (
              <option
                value={value?.title}
                key={index}
                className="font-semibold"
              >
                {value?.title}
              </option>
            );
          })}
      </select>
    </div>
  );
}

export default TopCourses;
