import React, { useCallback, useRef, useState } from "react";
import useGetVideos from "../../../hooks/getVideos/useGetVideos";
import VideoCompontent from "../../organization/videoCompont";
import VideoController from "../../organization/video_controller";

function VideoScroll() {
  const { data } = useGetVideos();
  const [curr, setCurr] = useState(0);
  const [mute, setMute] = useState(true);
  const videoRef = useRef();

  const changeIndex = useCallback((i, isMute) => {
    console.log(isMute);
    if (isMute === false) {
      setMute(true);

      setTimeout(() => {
        setCurr(i);
      }, 500);
    } else {
      setCurr(i);
    }
  }, []);

  const chageToMute = () => {
    setMute(!mute);
  };

  const gotToNext = (index) => {
    if (index < data.length - 1) {
      setCurr(index + 1);
    } else {
      setCurr(0);
    }
  };

  return (
    <div>
      <div className="md:h-64 w-full h-40 bg-white">
        <div className="overflow-hidden h-40  md:h-64">
          <div className="h-40 md:h-64 w-full transition-transform ease-out duration-500">
            {data?.map((value, index) => {
              return curr === index ? (
                <VideoCompontent
                  key={index}
                  index={index}
                  value={value}
                  gotToNext={gotToNext}
                  mute={mute}
                  videoRef={videoRef}
                />
              ) : null;
            })}
          </div>
        </div>
      </div>
      <VideoController
        videoRef={videoRef}
        changeIndex={changeIndex}
        data={data}
        currentIndex={curr}
        chageToMute={() => chageToMute()}
        isMute={mute}
      />
    </div>
  );
}

export default VideoScroll;
