import React from "react";
import SingleService from "../../organization/single_service";
import Data from "./Data";

function Services() {
  return (
    <div className="h-auto items-center justify-center mx-3 ">
      <div class="grid grid-col-1 md:grid-cols-2 gap-8 my-10 mx-5">
        {Data?.map((value, index) => {
          return <SingleService data={value} key={index} />;
        })}
      </div>
      <div className="h-10"></div>
    </div>
  );
}

export default Services;
