import React, { useCallback, useState } from "react";
import SearchBox from "../../components/molecules/search";
import TopCourses from "../../components/molecules/topCourses";
import Header from "../../components/organization/Header";
import AboutUs from "../../components/template/about_us";
import Admission from "../../components/template/admission";
import ApplayOnline from "../../components/template/applay_online";
import Drawer from "../../components/template/drawer";
import Services from "../../components/template/services";
// import SucessStories from "../../components/template/sucess stoires";
import VideoScroll from "../../components/template/videoScroll";
import BoatService from "../../components/template/boat_Service";
import useGetLocations from "../../hooks/getLocations";
import Footer from "../../components/template/footer";
import FormPopUp from "../../components/template/popup_form";

const HomeScreen = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  //hook
  const { data } = useGetLocations();

  const indiaIndex = data?.findIndex(value => value.title === "india") 
  const nationIndex = data?.findIndex(value => value.title === "international") 
  const india = data[indiaIndex]?.data
  const national =  data[nationIndex]?.data

  const openFunction = useCallback((value) => {
  setTimeout(() => {
    setIsOpen(value)

  }, 300);
  },[])



  return (
    <div className="w-screen">
      {/* header */}
   
      <header>
        <Header setIsOpen={(value)=>openFunction(value)} isOpen={isOpen} />
      </header>
  

      {/* mobile drawer show */}
      <div className="md:hidden">
        <Drawer isOpen={isOpen} setIsOpen={(value) => openFunction(value)}>
          <div className="md:hidden">
            <div className="my-4 w-3/4">
              <TopCourses />
            </div>
            <SearchBox />
          </div>
        </Drawer>
      </div>

      {/* ------------------------------------------------ */}
      <div>
      <FormPopUp/>
      {/* Video Scroll */}

      <VideoScroll />
      

      {/* applay online */}
      <ApplayOnline />

  
      {/* services */}
      <Services />

      {/* success Stories */}
      {/* <SucessStories /> */}

      {/* instagram */}

      <div className="h-auto w-full flex justify-center">
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <div class="elfsight-app-4c6e807a-f2a5-4dc3-9d0a-114b046a8b48"></div>
      </div>

      <div class="elfsight-app-550d5bba-02da-4634-9fa8-43a56a8dcb6e"></div>

      {/* Admission Domstic */}
      {
       india &&
        <Admission title="Admission India" data={india} />
      }
      {/* Admission International */}
      {
       national && 
        <Admission title="Admission International" data={national} />
      }   
      {/*About us  */}
      <AboutUs />
    {/* boat */}

        <BoatService />
    
        </div>

      {/* footer */}
      <Footer />
   
    </div>
  );
};

export default HomeScreen;
