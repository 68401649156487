import React from "react";

function SearchBox() {
  return (
    <div className="max-sm:mx-3 rounded-md h-10  flex flex-row bg-white w-72 items-center justify-between px-3">
      <input
        type="search"
        className="outline-none flex-1"
        placeholder="search college/courses"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
        />
      </svg>
    </div>
  );
}

export default SearchBox;
