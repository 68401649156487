import React from "react";

function VideoController({
  videoRef,
  changeIndex,
  data,
  currentIndex,
  chageToMute,
  isMute,
}) {
  const changeToFullScreen = () => {
    const el = videoRef.current;
    console.log(el);
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };

  return (
    <div className="bg-white h-14 w-full rounded-b-xl shadow-slate-300 shadow-lg flex flex-row px-3 items-center justify-between">
      <button
        className="w-8 h-8 rounded-full bg-[#2C387E] items-center justify-center flex"
        onClick={chageToMute}
      >
        {!isMute ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#fff"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#fff"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
            />
          </svg>
        )}
      </button>

      <div>
        <div className="h-7 w-auto min-w-[18rem] px-2 border-[1px] border-[#2C387E] rounded-lg flex flex-row justify-evenly items-center">
          {data.map((value, index) => (
            <button
              key={index}
              onClick={() => changeIndex(index, isMute)}
              className={
                currentIndex === index
                  ? "h-3 w-14 mx-1  rounded-md bg-[#2C387E]"
                  : "h-3 w-14 rounded-md mx-1 border-[#2C387E] border-[1px]"
              }
            />
          ))}
        </div>
      </div>
      <button onClick={() => changeToFullScreen()}>
        <svg
          id="Group_73"
          data-name="Group 73"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 38 38"
        >
          <g
            id="Rectangle_19"
            data-name="Rectangle 19"
            fill="none"
            stroke="#2c387e"
            strokeWidth="1"
          >
            <rect width="38" height="38" rx="5" stroke="none" />
            <rect x="0.5" y="0.5" width="37" height="37" rx="4.5" fill="none" />
          </g>
          <g
            id="Rectangle_20"
            data-name="Rectangle 20"
            transform="translate(0 14)"
            fill="none"
            stroke="#2c387e"
            strokeWidth="1"
          >
            <path
              d="M0,0H20a4,4,0,0,1,4,4V24a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
              stroke="none"
            />
            <path
              d="M.5.5H20A3.5,3.5,0,0,1,23.5,4V23.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V.5A0,0,0,0,1,.5.5Z"
              fill="none"
            />
          </g>
        </svg>
      </button>
    </div>
  );
}

export default VideoController;
