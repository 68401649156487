import React from "react";
import GirlImage from "../../../assets/image/girl.png";
import ApplayNowButtons from "../../organization/applay_now_buttons";
import GratingHeader from "../../organization/grating_header";
function ApplayOnline() {
  return (
    <div className="py-10  h-auto w-screen flex flex-row items-center justify-between">
      <div className="sm:w-[50%] px-10">
        <GratingHeader />
        <ApplayNowButtons />
      </div>
      <div className="w-96 h-50 items-end flex max-sm:hidden">
        <img
          src={GirlImage}
          className="h-full w-full object-cover rounded-l-[3rem] "
          alt="girl"
        />
      </div>
    </div>
  );
}

export default ApplayOnline;
