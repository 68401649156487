import React from "react";

function Devider() {
  return (
    <div>
      <div className="h-[0.15rem] w-40  bg-[#BDB80E]"></div>
      <div className="h-[0.15rem] w-40 mt-[0.1rem] bg-[#764AF1]"></div>
    </div>
  );
}

export default Devider;
