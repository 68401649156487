import axios from "axios";
import { useEffect, useState } from "react";
import { baseUrl } from "../../stging";

const useGetVideos = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getVideos();
  }, []);

  const getVideos = () => {
    // let videoString = localStorage.getItem("video");
    // let video = JSON.parse(videoString);
    // if (video) {
    //   setData(video);
    // } else {
      try {
        axios
          .get(`${baseUrl}video`)
          .then((res) => {
            // localStorage.setItem("video", JSON.stringify(res?.data?.data));
            setData(res?.data?.data);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.log(error);
      }
    // }
  };

  return { data };
};

export default useGetVideos;
