import React, { useState } from 'react'
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator, Avatar, VoiceCallButton, VideoCallButton, ConversationHeader, InfoButton } from '@chatscope/chat-ui-kit-react'
import LOGO from '../../../assets/image/child.jpeg'
const API_KEY = "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"

function AiChatBoat() {
  const [typing, setTyping] = useState(false)
  const [messages, setMessages] = useState([{
    message: "Hello iam SOC!",
    sender: "SOC"
  }])


  const handleSend = async (message) => {
    const newMessage = {
      message: message,
      sender: "user",
      direction: "outgoing"
    }
    const newMessages = [...messages, newMessage]
    setMessages(newMessages)
    setTyping(true)
    await proccessMessageToChatGPT(newMessages)
  }


  async function proccessMessageToChatGPT(chatMessages) {
    let apiMessage = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "SOC") {
        role = "assistant"
      }
      else {
        role = "user"
      }
      return { role: role, content: messageObject?.message }

    });

    const systemMessage = {
      role: "system",
      content: "Explain about latest courses in bangulare univercity"
    }

    const apiRequestBody = {
      "model": "gpt-3.5-turbo-0301",
      "messages": [
        systemMessage,
        ...apiMessage
      ],
      max_tokens: 1000,
      n: 1,
      temperature: 0.6,

    }
    let prompt = apiMessage[apiMessage.length - 1].content.toLowerCase()

    if (prompt.includes("students only choice" || "soc")) {
      setTimeout(() => {
        setMessages([
          ...chatMessages,
          {
            message: "Students Only Choice is an education consultancy located in Kozhikode, Kerala, and is owned by Sandeep and Bibek. They provide expert guidance and support to students who are looking to pursue higher education in India or abroad. Whether you need assistance with college admissions, test preparation, or career counseling, Students Only Choice can help you make informed decisions about your future.",
            sender: "SOC"
          }
        ]);
        setTyping(false);
        return;
      }, 500);

    }
    else if (prompt === "who are you") {
      setTimeout(() => {
        setMessages([
          ...chatMessages,
          {
            message: "My name is SOC, and I am an AI chatbot created to provide educational assistance and answer questions on a wide range of topics. Thank you for clarifying that. Is there anything else you would like to know or ask me?",
            sender: "SOC"
          }
        ]);
        setTyping(false);
        return;
      }, 500);

    }


    else if (prompt.includes("bibek")) {
      setTimeout(() => {
        setMessages([
          ...chatMessages,
          {
            message: "Bibek is one of the owners of Students Only Choice, an education consultancy located in Kozhikode, Kerala. He works with his partner Sandeep to provide guidance and support to students who are looking to pursue higher education in India or abroad. Bibek is an expert in the field of education and provides valuable insights to students to help them make informed decisions about their future.",
            sender: "SOC"
          }
        ]);
        setTyping(false);
        return;
      }, 500);

    }


    else if (prompt.includes("sandeep")) {
      setTimeout(() => {
        setMessages([
          ...chatMessages,
          {
            message: "Sandeep is one of the owners of Students Only Choice, an education consultancy located in Kozhikode, Kerala. He works with his partner Bibek to provide guidance and support to students who are looking to pursue higher education in India or abroad. Sandeep is an expert in the field of education and provides valuable insights to students to help them make informed decisions about their future.",
            sender: "SOC"
          }
        ]);
        setTyping(false);
        return;
      }, 500);

    }
    else {
      await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
      }).then((data) => {
        return data.json()
      }).then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data?.choices[0]?.message.content,
            sender: "SOC"
          }

        ])
        setTyping(false)
      })

    }






  }

  return (
    <div className='py-2'>
      <div className='relative h-96 w-80 md:h-[30rem] md:w-[25rem]'>
        <MainContainer className='pt-4'>
          <ChatContainer>
            <ConversationHeader>
              <Avatar src={LOGO} name="Akane" />
              <ConversationHeader.Content userName="Students Only Choice" info="Your educational Assistant" />
              <ConversationHeader.Actions>
                <a href="tel:+919567958956"><VoiceCallButton /></a>
                <a href="facetime:+919567958956"><VideoCallButton /></a>
                <InfoButton />
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList
              typingIndicator={typing ? <TypingIndicator content="SOC is typing" /> : null}


            >

              {
                messages.map((message, i) => {
                  return <Message key={i} model={message}>
                    {message.sender === "SOC" && <Avatar src={LOGO} name="SOC" />}
                  </Message>
                })
              }
            </MessageList>
            <MessageInput placeholder='Type message here' onSend={handleSend}

            />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  )
}

export default AiChatBoat